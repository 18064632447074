import { Suspense, useEffect } from "react";
import { useLocation } from "react-router";
import ErrorBoundary from "./ErrorBoundary";
import ReactGA from "react-ga";
import Loading from "./components/Loading";
import Router from "./Router";
import IdleTimeout from "./components/IdleTimeout";
import { UserActions } from "./redux/actions/user";
import { UserSelectors } from "./redux/selectors";
import BaseSchema from "./components/BaseSchema";
import { useRootRedirect } from "./hooks/useRootRedirect";
import { withProfiler } from "@sentry/react";

ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  const location = useLocation();
  const authenticated = UserSelectors.useSelectAuthenticated();
  const setLoggedIn = UserActions.useLogin();
  const setLoading = UserActions.useSetLoading();
  const fetchUser = UserActions.useFetchUser();
  useRootRedirect();

  useEffect(() => {
    if (location.pathname.includes("app")) {
      document.querySelector("body")?.classList.remove("body-bg");
    } else {
      document.querySelector("body")?.classList.add("body-bg");
    }

    if (!authenticated) {
      setLoading(true);

      const token = localStorage.getItem(
        process.env.REACT_APP_TOKEN_KEY as string
      );

      if (token && token.length > 0) {
        setLoggedIn(token).then(() => {
          setLoading(true);

          fetchUser();
        });
      }
    }
  }, [location.pathname, setLoggedIn, setLoading, authenticated, fetchUser]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <IdleTimeout />
        <BaseSchema />
        <Router />
      </Suspense>
    </ErrorBoundary>
  );
};

export default withProfiler(App);
