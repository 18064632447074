import { useEffect } from "react";
import { useHistory } from "react-router";
import { UserSelectors } from "../redux/selectors";

export const useRedirect = (type: "private" | "public", routeTo: string) => {
  const history = useHistory();
  const loggedIn = UserSelectors.useSelectAuthenticated();
  const loading = UserSelectors.useSelectUserLoading();
  const user = UserSelectors.useSelectUser();

  useEffect(() => {
    if (type === "public") {
      if ((loggedIn || user) && !loading) {
        history.push(routeTo);
      }
      return;
    } else if (type === "private") {
      if ((!loggedIn || !user) && !loading) {
        history.push(routeTo);
      }
      return;
    }
  }, [history, loading, loggedIn, routeTo, type, user]);
};
