import { lazy } from "react";
import { Route, Switch, useLocation } from "react-router";
import NotFound from "./components/NotFound";
import Landing from "./pages/Landing";
import StartViews from "./pages/StartViews";

const FAQ = lazy(() => import("./pages/Landing/FAQ/FAQ"));
const Onboarding = lazy(() => import("./pages/Landing/Onboarding/Onboarding"));

const Router = () => {
  const location = useLocation();

  return (
    <Switch location={location}>
      <Route path="/start">
        <StartViews />
      </Route>
      <Route path="/onboarding">
        <Onboarding />
      </Route>
      <Route exact path="/faq">
        <FAQ />
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
