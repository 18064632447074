import { lazy } from "react";
import { Route, Switch } from "react-router";
import { useRedirect } from "../../hooks/useRedirect";
import "./styles.scss";

const PartOne = lazy(() => import("./PartOne"));
const PartTwo = lazy(() => import("./PartTwo"));

const StartViews = () => {
  useRedirect("private", "/onboarding/login");

  return (
    <div className="start-views">
      <div className="top">
        <h2>Let's Get Started</h2>
        <p className="sub">
          Before you start you need to create a person. It can be you, someone
          you know, or someone made up.
        </p>
      </div>
      <Switch>
        <Route exact path="/start/2">
          <PartTwo />
        </Route>
        <Route path="/">
          <PartOne />
        </Route>
      </Switch>
    </div>
  );
};

export default StartViews;
