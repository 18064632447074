export const FADE_IN_ANIMATION_VARIANT = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

export const LINK_ACTIVE = "1";
export const LINK_INACTIVE = "0.5";
