import "./index.scss";

import { useMediaQuery } from "react-responsive";

import HeroImage from "../../assets/HeroImage.svg";

import { motion } from "framer-motion";
import { FADE_IN_ANIMATION_VARIANT } from "./constants";
import Navbar from "./Navbar";
import "./Navbar.scss";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import { lazy } from "react";
const LandingMobile = lazy(() => import("./LandingMobile"));

const Landing = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const useDesktopContent = useMediaQuery({ query: "(min-width: 1200px)" });

  const history = useHistory();

  const navigate = (path: string) => {
    history.push(path);
  };

  return (
    <div className="body">
      <Helmet>
        <title>Societize | Home</title>
      </Helmet>
      <div className="body-content">
        <Navbar isTabletOrMobile={isTabletOrMobile} />
        {!useDesktopContent && <LandingMobile navigate={navigate} />}
        {useDesktopContent && (
          <motion.div
            className="hero-content-mobile"
            initial="initial"
            variants={FADE_IN_ANIMATION_VARIANT}
            animate="animate"
          >
            <div className="left">
              <LazyLoadImage
                effect="opacity"
                src={HeroImage}
                alt="city landscape"
                style={
                  window.innerHeight <= 900
                    ? { height: "100px !important", width: "50%" }
                    : {}
                }
                className="hero-image"
              />

              <h1>Create Your Own Reality</h1>
              <p>
                Societize uses the power of AI and data driven algorithms to
                create a reality in which you ae the only one who can make
                yourself succced.
              </p>
              <button onClick={() => navigate("/onboarding/signup")}>
                Get Started
              </button>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Landing;
