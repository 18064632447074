import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { LINK_ACTIVE, LINK_INACTIVE } from "./constants";
import MobileMenu from "./MobileMenu";
import "./Navbar.scss";

const Navbar = ({ isTabletOrMobile }: { isTabletOrMobile: boolean }) => {
  const location = useLocation();

  return (
    <div className="navbar">
      <Logo />
      {isTabletOrMobile && <MobileMenu />}
      {!isTabletOrMobile && (
        <div className="navbar-desktop">
          <Link
            to="/"
            className="link"
            style={{
              opacity: location.pathname === "/" ? LINK_ACTIVE : LINK_INACTIVE,
            }}
          >
            Home
          </Link>

          <Link
            to="/faq"
            className="link"
            style={{
              opacity:
                location.pathname === "/faq" ? LINK_ACTIVE : LINK_INACTIVE,
            }}
          >
            FAQ
          </Link>

          <Link
            to="/onboarding"
            className="link"
            style={{
              opacity:
                location.pathname === "/onboarding"
                  ? LINK_ACTIVE
                  : LINK_INACTIVE,
            }}
          >
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
