import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "@material-ui/core";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LINK_ACTIVE, LINK_INACTIVE } from "./constants";

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="mobile-view">
      {!open && (
        <FontAwesomeIcon
          icon={faBars}
          color="white"
          className="mobile-hamburger"
          onClick={() => setOpen(true)}
        />
      )}
      <Drawer anchor="top" open={open} onClose={() => setOpen(false)}>
        <div className="hamburger-drawer">
          <div className="top">
            <FontAwesomeIcon
              icon={faBars}
              color="black"
              onClick={() => setOpen(false)}
              className="mobile-hamburger"
            />
          </div>
          <Link
            to="/"
            className="link"
            style={{
              opacity: location.pathname === "/" ? LINK_ACTIVE : LINK_INACTIVE,
            }}
          >
            Home
          </Link>
          <Link
            to="/onboarding"
            className="link"
            style={{
              opacity:
                location.pathname === "/onboarding"
                  ? LINK_ACTIVE
                  : LINK_INACTIVE,
            }}
          >
            Login
          </Link>
          <Link
            to="/faq"
            className="link"
            style={{
              opacity:
                location.pathname === "/faq" ? LINK_ACTIVE : LINK_INACTIVE,
            }}
          >
            FAQ
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileMenu;
