import svgLogo from "../assets/LogoLightSVG.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

export const Logo = () => {
  return (
    <LazyLoadImage
      className="logo"
      src={svgLogo}
      style={{ width: "100%" }}
      alt="societize"
      effect="opacity"
    />
  );
};
