import { useEffect } from "react";
import { useHistory } from "react-router";
import { UserSelectors } from "../redux/selectors";

export const useRootRedirect = () => {
  const loggedIn = UserSelectors.useSelectAuthenticated();
  const loading = UserSelectors.useSelectUserLoading();
  const user = UserSelectors.useSelectUser();
  const history = useHistory();

  useEffect(() => {
    if (loggedIn && user && user.personStep && user.personStep !== -1 && !loading) {
      user.personStep === 1
        ? history.push("/start")
        : history.push(`/start/${user.personStep}`);

      return;
    }

    if (loggedIn && user && !user?.personStep && !loading) {
      history.push("/start");
    }

    if (loggedIn && user && user.personStep === -1 && !loading) {
      history.push("/dashboard");
      return;
    }
  }, [loggedIn, loading, user, history]);
};
