import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type CacheControlScope =
  | 'PUBLIC'
  | 'PRIVATE';

export type ChildhoodEvent = {
  id: Scalars['Int'];
  personId: Scalars['Int'];
  description: Scalars['String'];
  rating?: Maybe<Scalars['Int']>;
};

export type CreatePersonAttributesInput = {
  personId: Scalars['Int'];
  skinColor: Scalars['String'];
  eyeColor: Scalars['String'];
  bodyType: Scalars['String'];
  heightFeet: Scalars['Int'];
  heightInches: Scalars['Int'];
  personalityId: Scalars['Int'];
};

export type CreatePersonInput = {
  userId: Scalars['Int'];
  firstName: Scalars['String'];
  middleName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Scalars['String'];
};

export type Mutation = {
  createPerson: Person;
  createPersonAttributes: Scalars['Boolean'];
  addPersonality: Personality;
  signup: Scalars['String'];
  login: Scalars['String'];
};


export type MutationCreatePersonArgs = {
  person: CreatePersonInput;
};


export type MutationCreatePersonAttributesArgs = {
  attributes?: Maybe<CreatePersonAttributesInput>;
};


export type MutationAddPersonalityArgs = {
  personality: PersonalityInput;
};


export type MutationSignupArgs = {
  userInput: UserInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Parent = {
  id: Scalars['Int'];
  childId: Scalars['Int'];
  name: Scalars['String'];
  gender: Scalars['String'];
};

export type Person = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
  firstName: Scalars['String'];
  middleName: Scalars['String'];
  lastName: Scalars['String'];
  birthdate: Scalars['String'];
  gender: Scalars['String'];
  createdAt: Scalars['String'];
  attributes?: Maybe<PersonAttributes>;
  wallet?: Maybe<PersonWallet>;
  wealthClass?: Maybe<WealthClass>;
  parents?: Maybe<Array<Maybe<Parent>>>;
};

export type PersonAttributes = {
  id: Scalars['Int'];
  personId: Scalars['Int'];
  skinColor: Scalars['String'];
  eyeColor: Scalars['String'];
  bodyType: Scalars['String'];
  heightFeet: Scalars['Int'];
  heightInches: Scalars['Int'];
  familyWealthId: Scalars['Int'];
  personality: Personality;
  childhoodEvents: Array<Maybe<ChildhoodEvent>>;
};

export type PersonWallet = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
  amount: Scalars['Int'];
};

export type Personality = {
  id: Scalars['Int'];
  title: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
};

export type PersonalityInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
};

export type Query = {
  getPersonalities: Array<Maybe<Personality>>;
  getUser: User;
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type User = {
  id: Scalars['Int'];
  idToken: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthdate: Scalars['String'];
  createdAt: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  username: Scalars['String'];
  person?: Maybe<Person>;
  personStep?: Maybe<Scalars['Int']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthdate: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type WealthClass = {
  id: Scalars['Int'];
  title: Scalars['String'];
  description: Scalars['String'];
};

export type CreatePersonMutationVariables = Exact<{
  person: CreatePersonInput;
}>;


export type CreatePersonMutation = { createPerson: { id: number } };

export type CreatePersonAttributesMutationVariables = Exact<{
  attributes?: Maybe<CreatePersonAttributesInput>;
}>;


export type CreatePersonAttributesMutation = { createPersonAttributes: boolean };

export type GetPersonalitisQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPersonalitisQuery = { getPersonalities: Array<Maybe<{ id: number, title: string, description: string, image?: Maybe<string> }>> };

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = { getUser: { id: number, idToken: string, firstName: string, lastName: string, createdAt: string, email: string, birthdate: string, username: string, personStep?: Maybe<number>, person?: Maybe<{ id: number }> } };

export type LoginUserMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginUserMutation = { login: string };

export type SignupUserMutationVariables = Exact<{
  userInput: UserInput;
}>;


export type SignupUserMutation = { signup: string };


export const CreatePersonDocument = gql`
    mutation CreatePerson($person: CreatePersonInput!) {
  createPerson(person: $person) {
    id
  }
}
    `;
export type CreatePersonMutationFn = Apollo.MutationFunction<CreatePersonMutation, CreatePersonMutationVariables>;

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      person: // value for 'person'
 *   },
 * });
 */
export function useCreatePersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(CreatePersonDocument, options);
      }
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>;
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>;
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<CreatePersonMutation, CreatePersonMutationVariables>;
export const CreatePersonAttributesDocument = gql`
    mutation CreatePersonAttributes($attributes: CreatePersonAttributesInput) {
  createPersonAttributes(attributes: $attributes)
}
    `;
export type CreatePersonAttributesMutationFn = Apollo.MutationFunction<CreatePersonAttributesMutation, CreatePersonAttributesMutationVariables>;

/**
 * __useCreatePersonAttributesMutation__
 *
 * To run a mutation, you first call `useCreatePersonAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonAttributesMutation, { data, loading, error }] = useCreatePersonAttributesMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useCreatePersonAttributesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePersonAttributesMutation, CreatePersonAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePersonAttributesMutation, CreatePersonAttributesMutationVariables>(CreatePersonAttributesDocument, options);
      }
export type CreatePersonAttributesMutationHookResult = ReturnType<typeof useCreatePersonAttributesMutation>;
export type CreatePersonAttributesMutationResult = Apollo.MutationResult<CreatePersonAttributesMutation>;
export type CreatePersonAttributesMutationOptions = Apollo.BaseMutationOptions<CreatePersonAttributesMutation, CreatePersonAttributesMutationVariables>;
export const GetPersonalitisDocument = gql`
    query GetPersonalitis {
  getPersonalities {
    id
    title
    description
    image
  }
}
    `;

/**
 * __useGetPersonalitisQuery__
 *
 * To run a query within a React component, call `useGetPersonalitisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalitisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalitisQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonalitisQuery(baseOptions?: Apollo.QueryHookOptions<GetPersonalitisQuery, GetPersonalitisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonalitisQuery, GetPersonalitisQueryVariables>(GetPersonalitisDocument, options);
      }
export function useGetPersonalitisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonalitisQuery, GetPersonalitisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonalitisQuery, GetPersonalitisQueryVariables>(GetPersonalitisDocument, options);
        }
export type GetPersonalitisQueryHookResult = ReturnType<typeof useGetPersonalitisQuery>;
export type GetPersonalitisLazyQueryHookResult = ReturnType<typeof useGetPersonalitisLazyQuery>;
export type GetPersonalitisQueryResult = Apollo.QueryResult<GetPersonalitisQuery, GetPersonalitisQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: Int!) {
  getUser(id: $id) {
    id
    idToken
    firstName
    lastName
    createdAt
    email
    birthdate
    username
    personStep
    person {
      id
    }
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($email: String!, $password: String!) {
  login(email: $email, password: $password)
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const SignupUserDocument = gql`
    mutation SignupUser($userInput: UserInput!) {
  signup(userInput: $userInput)
}
    `;
export type SignupUserMutationFn = Apollo.MutationFunction<SignupUserMutation, SignupUserMutationVariables>;

/**
 * __useSignupUserMutation__
 *
 * To run a mutation, you first call `useSignupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserMutation, { data, loading, error }] = useSignupUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useSignupUserMutation(baseOptions?: Apollo.MutationHookOptions<SignupUserMutation, SignupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupUserMutation, SignupUserMutationVariables>(SignupUserDocument, options);
      }
export type SignupUserMutationHookResult = ReturnType<typeof useSignupUserMutation>;
export type SignupUserMutationResult = Apollo.MutationResult<SignupUserMutation>;
export type SignupUserMutationOptions = Apollo.BaseMutationOptions<SignupUserMutation, SignupUserMutationVariables>;