import { useState } from "react";
import IdleTimer from "react-idle-timer";
import { apolloClient } from "../client";

const IdleTimeout = () => {
  const [, setIdleTimer] = useState<IdleTimer>();
  const [timedOut, setTimedOut] = useState(false);

  const onActive = () => {
    if (timedOut) {
      console.log("active");
      window.location.reload();
    }
  };

  const onIdle = () => {
    console.log("Stopped");
    setTimedOut(true);
    apolloClient.stop();
  };

  const onAction = () => {
    setTimedOut(false);
  };

  return (
    <IdleTimer
      ref={(ref) => setIdleTimer(ref)}
      onIdle={onIdle}
      element={document}
      onAction={onAction}
      onActive={onActive}
      debounce={250}
      // 10 Minutes
      timeout={600000}
    />
  );
};

export default IdleTimeout;
