import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "./redux/store";
import "./index.scss";
import { ApolloProvider } from "@apollo/client";
import App from "./App";
import { apolloClient } from "./client";
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";

init({
  dsn: process.env.REACT_APP_SENTRY_DSN as string,
  release: "app@1.4.0",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 1.0,
});

const rootElement = document.getElementById("root")!;

// throw new Error("Bruh");

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </Provider>,
    rootElement
  );
}
